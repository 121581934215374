<script lang="ts" setup>
import { FormKitNode } from '@formkit/core'

const props = defineProps<{
  name: string
  required: boolean
  label?: string
  placeholder?: string
  value?: string
  disabled?: boolean
  validation?: string
  validationMessages?: {
    [key: string]: string
  }
}>()

const attrs = useAttrs()
const hasValidation = ref(!!(props.required || props.validation))
const isFocused = ref(false)
const isFilled = ref(!!props.value)

const validationString = computed(() => {
  const validationString = props.validation ?? ''
  const v = validationString.split('|')
  if (props.required) {
    v.push('required')
  }

  return v.filter(Boolean).join('|')
})

const node = ref<FormKitNode | undefined>()
const setNode = (n: FormKitNode) => {
  if (n.value !== '') {
    isFilled.value = true
  }
  return (node.value = n)
}

watch(
  () => props.value,
  (value) => {
    if (!node.value) return
    node.value.input(value)
  },
)

const handleFocus = (f: boolean) => {
  isFocused.value = f
}

const classes: Ref<string> = ref('')
watch(
  [isFilled, isFocused, hasValidation, () => attrs.class],
  () => {
    classes.value = [
      isFilled.value ? 'is-filled' : '',
      isFocused.value ? 'is-focused' : '',
      hasValidation.value ? 'has-validation' : '',
      attrs.class ?? '',
      props.placeholder ? 'has-placeholder' : '',
    ]
      .filter(Boolean)
      .join(' ')
  },
  { immediate: true },
)
</script>

<template>
  <FormKit
    type="password"
    :name="name"
    :label="label ?? ''"
    :placeholder="placeholder ?? ''"
    :value="value ?? ''"
    :validation="validationString ?? ''"
    :validation-messages="validationMessages ?? {}"
    :disabled="disabled ?? false"
    :classes="{
      outer: classes,
    }"
    @focus="() => handleFocus(true)"
    @blur="() => handleFocus(false)"
    @input="(value?: string) => (value !== '' ? (isFilled = true) : (isFilled = false))"
    @node="setNode"
  />
</template>

<style lang="scss" scoped></style>
